<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns">
        <el-button type="primary" icon="el-icon-plus" class="btn_1" @click="addCommd">添加商品</el-button>
      </div>
      <!-- 商品列表 -->
      <el-table :height="'30rem'" :data="commodityList" border style="width: 100%">
        <el-table-column prop="proName" label="商品名称" width="180">
        </el-table-column>
        <el-table-column prop="img" label="商品图片" width="180">
          <template slot-scope="scope">
            <div>
              <img style="width: 100px;height: 60px;object-fit: contain;" :src="scope.row|srcImg" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="describe" label="商品描述" width="180">
          <template slot-scope="scope">
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              {{scope.row.proDescribed}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="proPrice" label="商品价格">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin: 15px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[5,10,20]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!-- 弹出层 -->
      <el-dialog @close="close" :title="currentDialog" :visible.sync="centerDialogVisible" width="70%" center>
        <div>
          <div style="height: 60vh;overflow-y:scroll;">
            <!-- 商品名称 -->
            <div class="dialogItem">
              <div class="title">商品名称：</div>
              <div class="input">
                <el-input v-model="comName" placeholder="请输入商品名称"></el-input>
              </div>
            </div>
            <!-- 商品图片 -->
            <div style="margin-bottom:60px">
              <div class="dialogItem">
                <div class="title">商品图片:</div>
                <div>
                  <el-upload :action="imgurl" list-type="picture-card" :on-success="handleAvatarSuccess" :limit="5"
                    :file-list="fileList" accept=".jpg, .jpeg, .png" :on-exceed="()=>$message.error('只能上传5张图片')"
                    :show-file-list="true" :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 描述文字 -->
              <div style="font-size:14px;color:red;">
                *商品图片用于商品详情页里轮播图展示，建议尺寸375*150
              </div>
            </div>
            <!-- 图文详情 -->
            <div style="margin-bottom:60px">
              <div class="dialogItem">
                <div class="title">图文详情:</div>
                <div>
                  <el-upload :action="imgurl" list-type="picture-card" :on-success="handleAvatarSuccess2" :limit="5"
                    :file-list="fileList2" accept=".jpg, .jpeg, .png" :on-exceed="()=>$message.error('只能上传5张图片')"
                    :show-file-list="true" :on-remove="handleRemove2">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 描述文字 -->
              <div style="font-size:14px;color:red;">
                *图文详情用于商品详情页里商品描述图片展示，建议尺寸宽度375px,可上传多张
              </div>
            </div>
            <!-- 服务详情 -->
            <div style="margin-bottom:60px">
              <div class="dialogItem">
                <div class="title">服务详情:</div>
                <div style="display:flex;align-items:center;">
                  <i @click="addOptionsList" class="el-icon-circle-plus-outline"
                    style="font-size:26px;margin-right:20px"></i>
                  <div>
                    <div v-for="(item,index) in optionsList" :key="index"
                      :style="optionsList.length<=1?'display:flex;align-items:center;':'display:flex;align-items:center;margin-bottom: 10px;'">
                      <el-input style="margin-right:20px;" size="mini" v-model="item.name" placeholder="请输入项目名称">
                      </el-input>
                      <el-input style="margin-right:20px;" size="mini" v-model="item.num" placeholder="请输入项目数量">
                      </el-input>
                      <el-input style="margin-right:20px;" size="mini" v-model="item.per" placeholder="请输入项目价格">
                      </el-input>
                      <i v-show="optionsList.length>1" @click="deleteOptionsList(index)" class="el-icon-remove-outline"
                        style="font-size:26px;margin-left:20px"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 描述文字 -->
              <div style="font-size:14px;color:red;">
                *服务详情用于商品详情页里服务项目列表展示,可添加多项
              </div>
            </div>
            <!-- 商品描述 -->
            <div class=" dialogItem">
              <div class="title">商品描述:</div>
              <div style="width:400px">
                <el-input type="textarea" :rows="2" placeholder="请输入商品描述" v-model="textarea">
                </el-input>
              </div>
            </div>
            <!-- 商品价格 -->
            <div class="dialogItem">
              <div class="title">商品价格:</div>
              <div class="input">
                <el-input type="number" v-model="price" placeholder="请输入商品价格"></el-input>
              </div>
            </div>
          </div>
          <div style="display:flex;justify-content:space-around">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitMap">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        commodityList: [],
        page: 1,
        limit: 5,// 每页显示
        total: 0,//总条数
        optionsList: [
          {
            name: '',
            num: '',
            per: '',
          }
        ],// 项目列表展示
        currentDialog: '',//弹框状态
        centerDialogVisible: false,// 弹框开关
        imgurl: 'https://www.idemer.com/web/upload/file',
        // imgurl: 'http://192.168.3.165:8069/upload/file',
        fileList: [],// 商品图片文件列表
        fileList2: [],// 图文详情文件列表
        textarea: '',// 商品描述
        comName: '',//商品名称
        price: '',// 商品价格
        currentItem: '',// 当前编辑项
      }
    },
    created() {
      this.getList()
    },
    filters: {
      srcImg(row) {
        return row.proPic.split(',')[0]
      }
    },
    methods: {
      // 添加项目列表子项
      addOptionsList() {
        if (this.optionsList.length > 10) {
          this.$message.error('服务项目列表最多添加10个！')
          return
        }
        this.optionsList.push(
          {
            name: '',
            num: '',
            per: '',
          }
        )
      },
      // 删除项目列表子项
      deleteOptionsList(index) {
        this.optionsList.splice(index, 1)
      },
      // 获取商品列表
      getList() {
        this.$http.post(`/pro/slelecAll`, null, {
          params: {
            current: this.page,
            size: this.limit,
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.commodityList = res.data.data.records
            this.total = res.data.data.total
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 添加商品
      addCommd() {
        this.centerDialogVisible = true
        this.currentDialog = '添加商品'
      },
      // 编辑
      handleEdit(index, row) {
        this.centerDialogVisible = true
        this.currentDialog = '编辑商品'
        this.comName = row.proName
        let arr = []
        let arr2 = row.proPic.split(',')
        for (let i = 0; i < arr2.length; i++) {
          arr.push({
            url: arr2[i],
            response: arr2[i],
          })
        }
        let proPicDetailsList = []
        let proPicDetailsList2 = row.proPicDetails ? row.proPicDetails.split(',') : []
        for (let i = 0; i < proPicDetailsList2.length; i++) {
          proPicDetailsList.push({
            url: proPicDetailsList2[i],
            response: proPicDetailsList2[i],
          })
        }
        this.fileList = arr
        this.fileList2 = proPicDetailsList
        this.currentItem = row
        this.textarea = row.proDescribed
        this.price = row.proPrice
        this.optionsList = JSON.parse(row.proText) || [
          {
            name: '',
            num: '',
            per: '',
          }
        ]
      },
      // 删除
      handleDelete(index, row) {
        this.$confirm('确认删除该商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(`/pro/delete`, null, {
            params: {
              id: row.id
            }
          }).then(result => {
            if (result.data.code == 200) {
              this.$message.success(result.data.msg)
              this.getList()
            }
          })
        });
      },
      //每页大小改变
      handleSizeChange(newSize) {
        this.limit = newSize
        this.getList()
      },
      // 弹框关闭
      close() {
        this.comName = ''
        this.fileList = []
        this.fileList2 = []
        this.currentItem = ''
        this.textarea = ''
        this.price = ''
        this.optionsList = [
          {
            name: '',
            num: '',
            per: '',
          }
        ]
      },
      // 当前页改变
      handleCurrentChange(newPage) {
        this.page = newPage
        this.getList()
      },
      // 确定
      submitMap() {
        if (this.comName && this.textarea && this.price && this.fileList.length > 0 && this.fileList2.length > 0) {
          let arr = []
          for (let i = 0; i < this.fileList.length; i++) {
            let item = this.fileList[i]
            arr.push(item.response)
          }
          let arr2 = []
          for (let i = 0; i < this.fileList2.length; i++) {
            let item = this.fileList2[i]
            arr2.push(item.response)
          }
          for (let i = 0; i < this.optionsList.length; i++) {
            let item = this.optionsList[i]
            if (!item.name && !item.num && !item.per) {
              this.$message.error('请填写完整信息')
              return
            }
          }
          let obj = {
            proName: this.comName,// 商品名称
            proPic: arr.join(','),// 商品图片
            proPicDetails: arr2.join(','),// 图文详情
            proDescribed: this.textarea,// 商品描述
            proPrice: this.price,// 商品价格
            proText: JSON.stringify(this.optionsList),// 服务详情
          }
          if (this.currentItem && this.currentItem.id) {
            obj.id = this.currentItem.id
          }
          let url = `/pro/insert`
          if (this.currentDialog == '编辑商品') {
            url = `/pro/update`
          }
          this.$http.post(url, obj).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.data)
              this.centerDialogVisible = false
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请填写完整数据')
        }
      },
      // 上传图片成功
      handleAvatarSuccess(res, file, fileList) {
        console.log(fileList)
        this.fileList = fileList
      },
      // 删除图片
      handleRemove(file, fileList) {
        this.fileList = fileList
      },
      // 上传图片成功
      handleAvatarSuccess2(res, file, fileList) {
        console.log(fileList)
        this.fileList2 = fileList
      },
      // 删除图片
      handleRemove2(file, fileList) {
        this.fileList2 = fileList
      },
    }
  }
</script>

<style scoped lang="less">
  .dialogItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      width: 100px;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>